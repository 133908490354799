import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/planes',
      name: 'planes',      
      component: () => import(/* webpackChunkName: "planes" */ './views/Planes.vue')
    },

    {
      path: '/blog',
      name: 'blog',
      component: () => import(/* webpackChunkName: "blog" */ './views/Blog.vue')
    },

    {
      path: '/soluciones',
      name: 'soluciones',
      component: () => import(/* webpackChunkName: "soluciones" */ './views/Soluciones.vue')
    },

    {
      path: '/contacto',
      name: 'contacto',
      component: () => import(/* webpackChunkName: "contacto" */ './views/Contacto.vue')
    },

    {
      // path: '/page:aviso-de-privacidad',
      path: '/page/:pageName',
      name: 'page',
      props: true,
      params: true,
      component: () => import(/* webpackChunkName: "page" */ './views/Page.vue')
    }

  ]
})
