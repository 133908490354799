// import "@/assets/css/styles.styl"

require('@/assets/css/styles.styl')


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// require('https://www.googletagmanager.com/gtag/js?id=UA-148340229-1')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



ga('set', 'page', router.currentRoute.path);
ga('send', 'pageview');

router.afterEach(( to, from ) => {
  ga('set', 'page', to.path);
  ga('send', 'pageview');
});
