<template>
  <div id="app">
    <header class="site-header">
      <div class="main-container">

        <div class="logo">
          <router-link to="/"><img src="@/assets/imgs/logo.png" height="auto" width="100%" alt="wise"></router-link>
        </div>

        <div class="mobile-menu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#777878" width="32" height="32" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
        </div>
<!--
        <div class="mobile-2 mobile-menu mobile-active">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#777878" width="32" height="32" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
        </div>
 -->
        <nav class="main-navigation">
          <ul>
              <li><router-link to="/">Inicio</router-link></li>
              <li><router-link to="/planes">Planes</router-link></li>
              <li><router-link to="/blog">Blog</router-link></li>
              <li><router-link to="/soluciones">Soluciones a la medida</router-link></li>
              <li><router-link to="/contacto">Contacto</router-link></li>              
          </ul>
        </nav>
      </div>      
    </header>

    <transition name="router-anim" enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <router-view/>
    </transition>

    <footer class="site-footer">
      <div class="main-container">
        <div>wise 2020</div>
        <div>
          <ul class="social-links">
            <li class="icon-instagram">
              <a target="_blank" href="//www.instagram.com/wiserestsol">Instagram Wise</a>
            </li>

            <li class="icon-facebook">
              <a target="_blank" href="//www.facebook.com/wiserestsol">Facebook Wise</a>
            </li>

            <li><router-link to="/page/aviso-de-privacidad">Aviso de Privacidad</router-link></li>

            <li><a target="_blank" href="//app.bewise.mx" class="login-link">Iniciar sesión</a></li>        
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="stylus">
html, body {
  height 100%
}

#app
  width 100%
  height 100%
  
.link-login
  border 1px solid
  font-weight 300
  font-size 1.56rem!important
  padding-left 1em


[class*='page-']
  min-height 100vh
  width 100%
  position relative

.router-anim-enter-active {
  animation: coming .3s;
  // animation-delay: .5s;
  opacity: 0;
}
.router-anim-leave-active {
  animation: going .3s;
}

@keyframes going {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes coming {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

</style>

<script async src="https://www.googletagmanager.com/gtag/js?id=UA-148340229-1"></script>
<script>
  export default {
    
    mounted(){
      // burguer menu
      document.querySelector(".mobile-menu").addEventListener('click', function(){
        document.querySelector(".mobile-menu").classList.toggle('mobile-active');
        document.querySelector(".main-navigation").classList.toggle('is-active');
      })


    }

  };
</script>


<!-- Global site tag (gtag.js) - Google Analytics -->
